import { Flex, Input, Spinner, Text, Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "../../../api/axios";
import ButtonDiv from "../../UIElements/Button";
import isEmail from "validator/lib/isEmail";
import { toast } from "react-toastify";

function WaitList(props) {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");

  const formData = new FormData();

  formData.append("email", email);

  const check = (email) => {
    if (!isEmail(email)) {
      return true;
    } else {
      return false;
    }
  };

  const joinWaitlist = async () => {
    const isError = check(email);

    if (isError) return setError("Please enter a valid email address");

    try {
      setLoading(true);
      setError("");
      const { data } = await axios.post("/wait-list/subscribe-list", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(data);
      setEmail("");
      setSuccess(true);
    } catch (error) {
      console.log(error);
      setEmail("");
      setError(error.response.data.email[0]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 7000);
  }, [success]);

  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 7000);
  }, [error]);

  return (
    <Flex
      alignItems={["center", "center", "center", "flex-start"]}
      w={["100vw", "100vw", "100%"]}
      my={["0", "0", "20px", "0"]}
      flexDirection={"column"}
    >
      <Flex
        bg={["#fff", "#fff", "#F5F5F5"]}
        p="2"
        flexDirection={["column", "column", "row"]}
        borderRadius={["40px", "40"]}
        w={["95vw", "95vw", "100%", "80%"]}
        // justifyContent={"center"}
        // alignItems={"center"}
      >
        <Input
          bg="#F5F5F5"
          outline={"#f5f5f5"}
          border="#F5F5F5"
          appearance={"#f5f5f5"}
          placeholder="leo@email.com"
          w={["100%", null]}
          borderRadius={"40px"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <ButtonDiv
          onClick={joinWaitlist}
          mt={["10px", null, "0"]}
          fontWeight={"700"}
          fontSize={"18px"}
        >
          {loading ? (
            <Spinner
              thickness="3px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="sm"
            />
          ) : (
            " Join waitlist"
          )}
        </ButtonDiv>
      </Flex>
      {error && (
        <Text color={"red"} fontSize={"12px"} fontWeight={"500"} pl={"15px"}>
          {error}
        </Text>
      )}
      {success && (
        <Text color={"green"} fontSize={"12px"} fontWeight={"500"} pl={"15px"}>
          Email has been added to waitlist
        </Text>
      )}
    </Flex>
  );
}

export default WaitList;
