import {
  Box,
  Button,
  Flex,
  Icon,
  ListItem,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ButtonDiv from "../UIElements/Button";
import { IoIosCloseCircleOutline } from "react-icons/io";

function DrawerItems({ navs, onClose }) {
  const [isActive, setIsActive] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location) setIsActive(location.pathname);
  }, [location]);

  return (
    <Box
      position={"absolute"}
      zIndex={"100"}
      bg={["linear-gradient(180deg, #8066FF 0%, #7B41FE 100%)"]}
      w={"100%"}
      h={"100%"}
      right={"0"}
      display={"flex"}
      flexDirection={"column"}
    >
      <Box
        colorScheme="teal"
        alignSelf={"flex-end"}
        mt={"15px"}
        mr={"30px"}
        borderRadius={"0"}
      >
        <Icon
          fontSize={"40px"}
          as={IoIosCloseCircleOutline}
          onClick={onClose}
          color={"#fff"}
        />
      </Box>
      <UnorderedList
        display={["flex"]}
        justifyContent="space-around"
        w="100%"
        alignItems="center"
        py="5"
        flexDirection={"column"}
        h={"50%"}
        ml={"-3px"}
      >
        {navs.map((nav) => (
          <ListItem
            key={nav.id}
            color={isActive === nav.link ? "#fff" : "#fff"}
            textDecoration={isActive === nav.link ? "underline" : ""}
            fontWeight="500"
            listStyleType={"none"}
            _hover={{ color: "#5B2DFF" }}
            transition="150ms ease"
            cursor="pointer"
            py={"20px"}
            textTransform={"uppercase"}
          >
            <Link fontSize={"17px"} to={nav.link}>
              {nav.name}
            </Link>
          </ListItem>
        ))}
      </UnorderedList>
      <Flex
        w={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"row"}
      >
        <Button
          mt={"20px"}
          ml={"15px"}
          border={"1px solid #8482FF"}
          bg={"#fff"}
          px={"30px"}
          color={"#8482FF"}
          borderRadius={"30px"}
          py={"25px"}
          textTransform={"uppercase"}
          fontWeight={"700"}
        >
          <Link color={"#8482FF"} to="/login">
            Login
          </Link>
        </Button>
        <Button
          textTransform={"uppercase"}
          mt={"20px"}
          ml={"15px"}
          border={"1px solid #8482FF"}
          bg={"#fff"}
          px={"30px"}
          color={"#8482FF"}
          borderRadius={"30px"}
          py={"25px"}
          fontWeight={"700"}
        >
          <Link color={"#8482FF"} to="/signup">
            Signup
          </Link>
        </Button>
      </Flex>
    </Box>
  );
}

export default DrawerItems;
