import {
  Box,
  Image,
  UnorderedList,
  ListItem,
  Hide,
  Input,
  Show,
  Flex,
  Icon,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { navs } from "../../../model";
import BodyText from "../../TextElements/BodyText";
import HeadingText from "../../TextElements/HeadingText";
import ButtonDiv from "../../UIElements/Button";
import { Link, useLocation, useHref } from "react-router-dom";
import { HiOutlineMenu } from "react-icons/hi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useDrawer from "../../../hooks/useDrawer";
import Drawer from "../../drawer/Drawer";
import DrawerItems from "../../drawer/DrawerItems";
import WaitList from "./WaitList";

function Header(props) {
  const [isActive, setIsActive] = useState("");
  const location = useLocation();

  // const [isOpen, setIsOpen] = useState(false);

  const history = useHref();

  useEffect(() => {
    if (location) setIsActive(location.pathname);
  }, [location]);

  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <Box
      display={["null", "flex"]}
      flex="1"
      justifyContent="space-between"
      flexDirection={["column", "column", "column", "row"]}
      px={["0", "0", "0", "0", "0", "280px"]}
    >
      <Flex
        flex={["1", "1", "1", ".45"]}
        justifyContent={["flex-start", "flex-start", "flex-start", "flex-end"]}
        alignItems={["flex-start", "flex-end"]}
        flexDirection={["column", "column", "column", "row"]}
        bg={["linear-gradient(180deg, #8066FF 0%, #7B41FE 100%)"]}
      >
        <Show below="lg">
          <Box
            colorScheme="teal"
            alignSelf={"flex-end"}
            mt={"15px"}
            mr={"30px"}
            borderRadius={"0"}
          >
            <Icon
              onClick={onOpen}
              as={HiOutlineMenu}
              color={"#fff"}
              fontSize={"30px"}
            />
          </Box>

          {isOpen && (
            <Drawer>
              <DrawerItems navs={navs} onClose={onClose} />
            </Drawer>
          )}

          {/* <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton /> */}

          {/* <DrawerBody>
                
              </DrawerBody> */}

          {/* <DrawerFooter>
                
              </DrawerFooter>
            </DrawerContent>
          </Drawer> */}

          <Flex
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            px={"15px"}
            mt={"25px"}
          >
            <HeadingText
              fontSize="50px"
              textAlign={"center"}
              fontWeight="400"
              mt="20px"
              color={"#fff"}
            >
              Swap crypto and your gift cards easily
            </HeadingText>
            <BodyText
              py="4"
              fontSize={["13px", "13px", "14px"]}
              textAlign={"center"}
              color={"#fff"}
              px={["20px", "20px", "100px"]}
            >
              Trade your bitcoins and get paid to your bank account instantly.
              No stress Why settle for less when you can have more, sign up
              today and enjoy the sweetest rates
            </BodyText>
          </Flex>
        </Show>
        <Box
          width={["100%", "100%"]}
          display={"flex"}
          justifyContent={"flex-end"}
          mt={["15px", "10px", "0"]}
        >
          <LazyLoadImage
            src="hero-img.png"
            w={["100%", "100%", "100%", "100%"]}
            alignSelf="flex-end"
            display="flex"
            alt="home-image"
            // effect="blur"
            // PlaceholderSrc={PlaceholderImage}
          />
        </Box>
      </Flex>
      <Flex
        flex=".55"
        flexDirection={"column"}
        bg={"#fff"}
        justifyContent={["center"]}
        alignItems={["center"]}
      >
        <Show above="lg">
          <Box w="100%">
            <UnorderedList
              display={["flex"]}
              justifyContent="space-around"
              w="100%"
              alignItems="center"
              py="5"
            >
              {navs.map((nav) => (
                <ListItem
                  key={nav.id}
                  color={isActive === nav.link ? "#5B2DFF" : "#777575"}
                  textDecoration={isActive === nav.link ? "underline" : ""}
                  fontWeight="normal"
                  listStyleType={"none"}
                  _hover={{ color: "#5B2DFF" }}
                  transition="150ms ease"
                  cursor="pointer"
                >
                  <Link to={nav.link}>{nav.name}</Link>
                </ListItem>
              ))}

              <ButtonDiv
                bg={" linear-gradient(135deg, #8482FF 0%, #7723FE 100%);"}
              >
                <Link to="/login">Login</Link>
              </ButtonDiv>
            </UnorderedList>
          </Box>
        </Show>
        <Flex
          p={"10"}
          g={"#fff"}
          flexDirection={"column"}
          justifyContent={["center", "center", "center", "flex-start"]}
          alignItems={["center", "center", "center", "flex-start"]}
          bg={"#fff"}
          w={"100%"}
        >
          <Box
            display={"flex"}
            justifyContent="flex-start"
            border="1px"
            borderColor="gray.200"
            width={"fit-content"}
            px={["40px", "5"]}
            py={["7px", "1"]}
            borderRadius={"20"}
            my={["15px"]}
          >
            <BodyText>App coming soon🥳</BodyText>
          </Box>
          <Hide below="lg">
            <HeadingText fontSize="70" fontWeight="400" pr="20">
              Swap crypto and your gift cards easily
            </HeadingText>
            <BodyText py="4" pr="20" fontSize={["13px", "13px", "14px"]}>
              Trade your bitcoins and get paid to your bank account instantly.
              No stress Why settle for less when you can have more, sign up
              today and enjoy the sweetest rates
            </BodyText>
          </Hide>
          <WaitList />
          <Flex mt="5">
            <Box>
              <HeadingText fontSize={["40px"]} fontWeight={["normal"]}>
                10M+
              </HeadingText>
              <BodyText fontSize="12 ">Successful exchanges</BodyText>
            </Box>
            <Box ml="10">
              <HeadingText fontSize={["40px"]} fontWeight={["normal"]}>
                160+
              </HeadingText>
              <BodyText fontSize="12">Token swaps</BodyText>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}

export default Header;
