import { Image, Box, Flex, Icon } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import BodyText from "../../TextElements/BodyText";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import axios from "axios";

const Listings = () => {
  const [coins, setCoins] = useState([
    {
      id: 1,
      img: "bitcoin.png",
      name: "BTC",
      amount: 0,
    },
    {
      id: 2,
      img: "iota.png",
      name: "IOTA",
      amount: 0,
    },

    {
      id: 3,
      img: "nem.png",
      name: "NEM",
      amount: 0,
    },
    {
      id: 4,
      img: "xrp.png",
      name: "XRP",
      amount: 0,
    },
  ]);
  const [price, setPrice] = useState([]);

  const getCoin = async () => {
    try {
      const { data } = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cripple%2Ciota%2Cnem&vs_currencies=usd"
      );
      setPrice(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCoin();
  }, []);

  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      my={[null, null, "10px", "10"]}
    >
      <Flex
        width={["100%", "100%", "90%", "90%"]}
        bg="#F5F5F5"
        p={["5px", "5px", "10px", "20px"]}
        borderRadius={"40"}
        justifyContent={["center"]}
      >
        <Flex px={[null, null, "20px", "10"]}>
          <Image
            src={coins[0].img}
            alt={coins[0].name}
            w={[null, null, "15px", "5"]}
            h={[null, null, "15px", "5"]}
          />
          <BodyText color="#000" fontSize={[null, null, "12px", null]}>
            {coins[0].name}
          </BodyText>
          <BodyText color="#000" fontSize={[null, null, "12px", null]}>
            - ${price?.bitcoin?.usd}
          </BodyText>
        </Flex>
        <Flex px={[null, null, "20px", "10"]}>
          <Image
            src={coins[1].img}
            alt={coins[1].name}
            w={[null, null, "15px", "5"]}
            h={[null, null, "15px", "5"]}
          />
          <BodyText color="#000" fontSize={[null, null, "12px", null]}>
            {coins[1].name}
          </BodyText>
          <BodyText color="#000" fontSize={[null, null, "12px", null]}>
            - ${price?.iota?.usd}
          </BodyText>
        </Flex>
        <Flex px={[null, null, "20px", "10"]}>
          <Image
            src={coins[2].img}
            alt={coins[2].name}
            w={[null, null, "15px", "5"]}
            h={[null, null, "15px", "5"]}
          />
          <BodyText color="#000" fontSize={[null, null, "12px", null]}>
            {coins[2].name}
          </BodyText>
          <BodyText color="#000" fontSize={[null, null, "12px", null]}>
            - ${price?.nem?.usd}
          </BodyText>
        </Flex>
        <Flex px={[null, null, "20px", "10"]}>
          <Image
            src={coins[3].img}
            alt={coins[3].name}
            w={[null, null, "15px", "5"]}
            h={[null, null, "15px", "5"]}
          />
          <BodyText color="#000" fontSize={[null, null, "12px", null]}>
            {coins[3].name}
          </BodyText>
          <BodyText color="#000" fontSize={[null, null, "12px", null]}>
            - ${price?.ripple?.usd}
          </BodyText>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Listings;
