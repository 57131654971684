import { Box } from "@chakra-ui/react";
import React from "react";

function Drawer(props) {
  return (
    <Box zIndex={"20"} position={"fixed"} inset={"0"} h={"100vh"} w={"100vw"}>
      {props.children}
    </Box>
  );
}

export default Drawer;
