import {
  ListItem,
  UnorderedList,
  Flex,
  Show,
  Icon,
  Button,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { navs } from "../../model";
import ButtonDiv from "../UIElements/Button";
import { useLocation, Link, useHref } from "react-router-dom";
import { HiOutlineMenu } from "react-icons/hi";
import useDrawer from "../../hooks/useDrawer";
import DrawerItems from "../drawer/DrawerItems";
import Drawer from "../drawer/Drawer";

function Header(props) {
  const location = useLocation();
  const [isActive, setIsActive] = useState("");

  // const { isOpen, onOpen, onClose } = useDrawer();
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const history = useHref();

  useEffect(() => {
    if (location) setIsActive(location.pathname);
  }, [location]);

  return (
    <Flex
      justifyContent={"space-between"}
      alignItems={"center"}
      py={["5"]}
      flex={"1"}
      px={["0", "0", "0", "0", "0", "150px"]}
    >
      <Show below="lg">
        <Flex
          colorScheme="teal"
          alignSelf={"flex-end"}
          mt={"15px"}
          mr={"30px"}
          borderRadius={"0"}
          justifyContent={"flex-end"}
          w={"100%"}
        >
          <Icon
            onClick={onOpen}
            as={HiOutlineMenu}
            color={"#5B2DFF"}
            fontSize={"30px"}
          />
        </Flex>

        {isOpen && (
          <Drawer>
            <DrawerItems navs={navs} onClose={onClose} />
          </Drawer>
        )}
      </Show>
      <Show above="lg">
        <Flex w={"100%"} alignItems={"center"} mb={"30px"}>
          <UnorderedList
            display={"flex"}
            justifyContent="space-around"
            flex={".7"}
          >
            {navs.map((nav) => (
              <ListItem
                key={nav.id}
                color={isActive === nav.link ? "#5B2DFF" : "#777575"}
                textDecoration={isActive === nav.link ? "underline" : ""}
                fontWeight="normal"
                listStyleType={"none"}
                _hover={{ color: "#5B2DFF" }}
                transition="150ms ease"
                cursor="pointer"
              >
                <Link to={nav.link}>{nav.name}</Link>
              </ListItem>
            ))}
          </UnorderedList>
          <Flex
            flex=".25"
            justifyContent={"space-between"}
            alignItems="center"
            pr="20px"
          >
            <Link color="#777575" fontWeight="normal" mr="20" to="/signup">
              Create Account
            </Link>
            <ButtonDiv>
              <Link to="/login">Login</Link>
            </ButtonDiv>
          </Flex>
        </Flex>
      </Show>
    </Flex>
  );
}

export default Header;
